<template>
    <v-card id="course-card" flat class="d-flex" height="173" outlined :class="{'course-card-disabled': course.is_finished || course.percentage_achievement >= 100}">
        <router-link v-if="$vuetify.breakpoint.smAndUp || !hideImageForMobile" :to="routeShowLesson ? routeShowLesson : routeShowCourse" class="text--no-decoration" @click="$emit('closeGlobalSearch')">
            <v-avatar
                size="173"
                style="margin-top: -2px; margin-left: -2px;"
                tile>
                <v-img height="100%" :src="getBackground(courseContent)"
                       style="border-top-left-radius: 10px; border-bottom-left-radius: 10px">
                    <v-row align="center" class="fill-height mt-1">
                        <v-col class="text-center">
                            <v-btn fab depressed
                            @click="$emit('closeGlobalSearch')"
                                   height="56"
                                   width="56"
                                   :color="$vuetify.theme.dark ? 'secondary' : 'light'"
                                   class="elevation-2 justify-center">
                                <v-icon size="35" :color="$vuetify.theme.dark ? 'accent' : 'primary'">mdi-play
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-img>
            </v-avatar>
        </router-link>

        <v-card
                width="100%"
                flat
                style="margin: auto"
                class="no-radius-left no-border-left py-0">
            <v-list-item class="pl-6 pt-2">
                <v-list-item-content>
                    <p v-if="!course.is_started" class="font-weight-bold" style="color: green;">Nouveau</p>
                    <v-list-item-title class="mb-0 text-h6 font-weight-bold text-wrap" style="max-height: 75px">
                        {{ courseContent.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                        v-html="currentCourseModule.title"
                        class="pt-1 pb-3 mb-0 text-wrap paragraph-no-margin">
                    </v-list-item-subtitle>
                </v-list-item-content>

                <template v-if="$vuetify.breakpoint.mdAndUp">
                    <v-list-item-action v-if="showFavoriteBtn">
                        <v-tooltip color="#002145"
                                   bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="error"
                                       icon rounded depressed small
                                       v-bind="attrs"
                                       @click="toggleUserCourseFavorite(!course.is_favorite)"
                                       v-on="on">
                                    <v-icon>{{ course.is_favorite ? 'mdi-heart' : 'mdi-heart-outline'}}</v-icon>
                                </v-btn>
                            </template>

                            <v-list dark color="#002145"
                                    class="py-0"
                                    min-width="165px" max-width="165px"
                                    allow-overflow>
                                <v-list-item class="px-0 pl-2">
                                    <v-list-item-action class="mx-0 mr-2">
                                        <v-icon color="error">
                                            mdi-heart
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Favori
                                        </v-list-item-title>
                                        <v-list-item-title
                                            style="font-size: 0.833rem"
                                            class="text-wrap font-weight-light">
                                            Clic pour modifier
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-tooltip>
                    </v-list-item-action>
                    <v-list-item-action v-if="showCompleteBtn">
                        <v-tooltip color="#002145"
                                   bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="success"
                                       icon rounded depressed small
                                       v-bind="attrs"
                                       @click="toggleUserCourseFinish(!course.is_finished)"
                                       v-on="on">
                                    <v-icon>{{ course.is_finished ? 'mdi-flag' : 'mdi-flag-outline'}}</v-icon>
                                </v-btn>
                            </template>

                            <v-list dark color="#002145"
                                    class="py-0"
                                    min-width="165px" max-width="165px"
                                    allow-overflow>
                                <v-list-item class="px-0 pl-2">
                                    <v-list-item-action class="mx-0 mr-2">
                                        <v-icon color="success">
                                            mdi-flag
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Archivé
                                        </v-list-item-title>
                                        <v-list-item-title
                                            style="font-size: 0.833rem"
                                            class="text-wrap font-weight-light">
                                            Clic pour modifier
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-tooltip>
                    </v-list-item-action>
                </template>
            </v-list-item>
            <v-list-item v-if="showCourseProgression"
                         style="min-height: 20px; height: 20px;" class="pl-6">
                <v-list-item-content>
                    <v-list-item-title class=" text-subtitle-1">
                        <v-progress-linear
                            :value="course.percentage_achievement"
                            color="primary"
                            background-opacity=".25"
                            rounded
                            height="10"
                        ></v-progress-linear>
                    </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action class="text-subtitle-1 my-0">
                    {{ course.percentage_achievement ? course.percentage_achievement : 0 }}%
                </v-list-item-action>
            </v-list-item>
                <v-list-item class="pb-0 float-left pl-6">
                    <v-list-item-content>
                        <v-list-item-title class="text-center" @click="$emit('closeGlobalSearch')">
                            <router-link :to="routeShowCourse"
                                class="text-h4 font-weight-bold primary--text text--no-decoration">
                                Voir le cours
                            </router-link>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
        </v-card>
    </v-card>
</template>


<style scoped>
</style>

<script>
    import PageStore from "mg-js-core/lib/stores/PageStore";
    import CourseModuleLogic from "mg-js-core/lib/logics/CourseModuleLogic";
    import LessonLogic from "mg-js-core/lib/logics/LessonLogic";
    import CourseLogic from "mg-js-core/lib/logics/CourseLogic";

    // const defaultCardBg = ;

    export default {
        name: 'course-item',

        props: {
            course: {
                type: Object,
                required: true
            },
            routeNameShowCourse: {
                type: String,
                // required: true
            },
            routeNameShowCourseModule: {
                type: String,
                // required: true
            },
            routeNameShowLesson: {
                type: String,
                // required: true
            },
            empty: {
                type: Boolean,
                default: false
            },
            showFavoriteBtn: {
                type: Boolean,
                default: true
            },
            showCompleteBtn: {
                type: Boolean,
                default: true
            },

            showCourseProgression: Boolean,
            showLogo: Boolean,

            showLessonBtn: {
                type: Boolean,
                default: false,
            },
            hideImageForMobile: Boolean,
        },

        data: () => ({
            PageStore,
            cardBg: [
                require('@/assets/default-bg.jpg'),
                require('@/assets/blues-bg.jpg'),
                require('@/assets/funk-bg.png'),
                require('@/assets/metal-bg.png'),
                require('@/assets/pop-bg.png'),
                require('@/assets/rock-bg.png'),
                require('@/assets/variete-francaise-bg.png'),
            ]
        }),

        mounted() {
        },

        computed: {
            courseContent() {
                return this.empty ? this.course : this.course.course.data
            },

            currentCourseModule() {
                return this.empty ? null : this.getRelatedModule(this.course.currentCourseModuleDripContent.data)
            },

            currentLesson() {
                return this.empty ? null : this.getRelatedLesson(this.course.currentUserCourseModuleLesson.data)
            },

            /*  utils */

            routeShowCourse() {
                if (this.currentCourseModule) {
                    return {
                        name: this.routeNameShowCourseModule,
                        params: {
                            courseUuid: this.courseContent.slug,
                            courseModuleUuid: this.currentCourseModule.slug,
                        }
                    }

                } else {
                    return {
                        name: this.routeNameShowCourse,
                        params: {
                            courseUuid: this.courseContent.slug,
                        }
                    }
                }

            },

            routeShowLesson() {
                return this.empty ? null : {
                    name: this.routeNameShowLesson,
                    params: {
                        courseUuid: this.courseContent.slug,
                        courseModuleUuid: this.currentCourseModule.slug,
                        lessonUuid: this.course.currentCourseModuleDripContent.data.currentUserCourseModuleLesson ?
                            this.course.currentCourseModuleDripContent.data.currentUserCourseModuleLesson.data.lesson.data.slug :
                            this.currentLesson.slug
                    }
                }
            },
        },

        watch: {},

        methods: {
            getBackground(course) {
                if (course.background) {
                    return course.background.data.url;
                } else {
                    const tagName = course.tagStyles && course.tagStyles.data && course.tagStyles.data[0]
                        ? course.tagStyles.data[0].title
                        : '';

                    if (tagName.toLowerCase() === 'blues') {
                        return this.cardBg[1]
                    } else if (tagName.toLowerCase() === 'funk') {
                        return this.cardBg[2]
                    } else if (tagName.toLowerCase() === 'métal') {
                        return this.cardBg[3]
                    } else if (tagName.toLowerCase() === 'rock') {
                        return this.cardBg[5]
                    } else if (tagName.toLowerCase() === 'pop') {
                        return this.cardBg[4]
                    } else if (tagName.toLowerCase() === 'variété française') {
                        return this.cardBg[6]
                    }

                    return this.cardBg[0]
                }
            },

            getRelatedModule(courseModuleDripContent) {
                return courseModuleDripContent.courseModule && courseModuleDripContent.courseModule.data
                    ? courseModuleDripContent.courseModule.data
                    : CourseModuleLogic.getEmptyObject()
            },

            getRelatedLesson(courseModuleDripContentLesson) {
                return courseModuleDripContentLesson.lesson && courseModuleDripContentLesson.lesson.data
                    ? courseModuleDripContentLesson.lesson.data
                    : LessonLogic.getEmptyObject()
            },

            getRelatedCourse(userCourse) {
                return userCourse.course && userCourse.course.data
                    ? userCourse.course.data
                    : CourseLogic.getEmptyObject()
            },

            toggleUserCourseFavorite(value) {
                this.updateUserCourse({
                    is_favorite: value
                })

                this.$emit('update:course', {
                    ...this.course,
                    is_favorite: value
                })
            },

            toggleUserCourseFinish(value) {
                this.updateUserCourse({
                    is_finished: value
                })

                this.$emit('update:course', {
                    ...this.course,
                    is_finished: value
                })
            },

            updateUserCourse(body) {
                this.$emit('updateUserCourse', this.courseContent.id, body)
            },

            getHeight(courseRef){
                setTimeout(() => {
                    console.warn(courseRef)
                    console.warn(this.$refs[courseRef].$el)
                    console.warn(this.$refs[courseRef].$el ? this.$refs[courseRef].$el.offsetHeight : 100)
                }, 1000)
            }
        }
    }
</script>

<style scoped>
.course-card-disabled {
  opacity: 0.5; /* Rendre le contenu moins visible */
}

</style>
